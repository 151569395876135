import VueRouter from 'vue-router';
// Pages

import HomeGreenCard from "./views/ideas/green-card/HomeGreenCard";

import HomeGreenCardStarterKit from "./views/ideas/green-card/HomeGreenCardStarterKit";
import HomeGreenCardSelfpetitionAI from "./views/ideas/green-card/HomeGreenCardSelfpetitionAI";
import HomeGreenCardPricing from "./views/ideas/green-card/HomeGreenCardPricing";
import HomeGreenCardContact from "./views/ideas/green-card/HomeGreenCardContact";

import SelectProduct from "./views/ideas/green-card/SelectProduct";
import CheckoutStarterKit from "./views/ideas/green-card/CheckoutStarterKit";
import CheckoutSelfpetitionAI from "./views/ideas/green-card/CheckoutSelfpetitionAI";
import CheckoutRfeResponse from "./views/ideas/green-card/CheckoutRfeResponse";
import GreenCardOrdered from "./views/ideas/green-card/GreenCardOrdered";
import GreenCardOrderedRfe from "./views/ideas/green-card/GreenCardOrderedRfe";

import GreenCardFAQView from "./views/ideas/green-card/GreenCardFAQView";
import GreenCardAIView from "./views/ideas/green-card/GreenCardAIView";
import GreenCardAiForm2View from "./views/ideas/green-card/GreenCardAiForm2View";

import GreenCardTypeformView from "./views/ideas/green-card/GreenCardTypeformView"

import ProfessionSoftwareEngineerITView from "./views/ideas/green-card/ProfessionSoftwareEngineerITView";
import ProfessionBankingFinanceView from "./views/ideas/green-card/ProfessionBankingFinanceView";
import ProfessionDataScientistAnalystView from "./views/ideas/green-card/ProfessionDataScientistAnalystView";
import ProfessionProductProjectMgmtView from "./views/ideas/green-card/ProfessionProductProjectMgmtView";
import ProfessionNetworkElectricalEngView from "./views/ideas/green-card/ProfessionNetworkElectricalEngView";
import ProfessionElectroMechanicalEngView from "./views/ideas/green-card/ProfessionElectroMechanicalEngView";
import ProfessionChemicalEngineerView from "./views/ideas/green-card/ProfessionChemicalEngineerView";
import ProfessionMarketingView from "./views/ideas/green-card/ProfessionMarketingView";
import ProfessionHRRecruiterView from "./views/ideas/green-card/ProfessionHRRecruiterView";
import ProfessionScienceTeacherView from "./views/ideas/green-card/ProfessionScienceTeacherView";
import ProfessionPhysicianDentistView from "./views/ideas/green-card/ProfessionPhysicianDentistView";
import ProfessionPilotView from "./views/ideas/green-card/ProfessionPilotView";
import ProfessionTechEntrepreneurView from "./views/ideas/green-card/ProfessionTechEntrepreneurView";


export const router = new VueRouter({
    mode: 'history',
    linkExactActiveClass: 'active',
    scrollBehavior() {
      return { x: 0, y: 0 };
    },
    routes: [
      { path: '/', component: HomeGreenCard, name: 'Home' },
      
      { path: '/starterkit', component: HomeGreenCardStarterKit },
      { path: '/selfpetition-ai', component: HomeGreenCardSelfpetitionAI },
      { path: '/prices', component: HomeGreenCardPricing },
      { path: '/contact', component: HomeGreenCardContact },
      
      { path: '/select-product', component: SelectProduct},
      { path: '/checkout-starter-kit', component: CheckoutStarterKit},
      { path: '/checkout-selfpetition-ai', component: CheckoutSelfpetitionAI},
      { path: '/checkout-rfe-response', component: CheckoutRfeResponse},
      { path: '/ordered', component: GreenCardOrdered },
      { path: '/ordered-rfe', component: GreenCardOrderedRfe },
      
      { path: '/faq', component: GreenCardFAQView, name: 'Faq'},
      { path: '/ai', component: GreenCardAIView},
      { path: '/aiform2', component: GreenCardAiForm2View},

      { path: '/are-you-eligible', component: GreenCardTypeformView },

      { path: '/software-engineer-it-sample', component: ProfessionSoftwareEngineerITView },
      { path: '/banking-finance-sample', component: ProfessionBankingFinanceView },
      { path: '/data-scientist-analyst-sample', component: ProfessionDataScientistAnalystView },
      { path: '/product-project-mgmt-sample', component: ProfessionProductProjectMgmtView },
      { path: '/network-electrical-engineer-sample', component: ProfessionNetworkElectricalEngView },
      { path: '/electro-mechanical-engineer-sample', component: ProfessionElectroMechanicalEngView },
      { path: '/chemical-engineer-sample', component: ProfessionChemicalEngineerView },
      { path: '/marketing-sample', component: ProfessionMarketingView },
      { path: '/hr-recruiter-sample', component: ProfessionHRRecruiterView },
      { path: '/science-teacher-sample', component: ProfessionScienceTeacherView },
      { path: '/physician-dentist-sample', component: ProfessionPhysicianDentistView },
      { path: '/pilot-sample', component: ProfessionPilotView },
      { path: '/tech-entrepreneur-sample', component: ProfessionTechEntrepreneurView },

      // otherwise redirect to home
      { path: '*', redirect: '/' }
    ]
});

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page

    next();
})
