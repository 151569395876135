<template>
    <!-- Start Navbar Area -->
    <header id="header" :class="['headroom', {'is-sticky': isSticky}]">
        <div class="startp-nav">
<!--            <div class="container">-->
                <nav class="navbar navbar-expand-md navbar-light">
                    <router-link class="navbar-brand" to="/">
                        <img width="150px" src="./../resources/selfpetitionusa-logo.svg" alt="Self Petition USA"/>
                    </router-link>

                    <b-navbar-toggle target="navbarSupportedContent"></b-navbar-toggle>


                    <b-collapse class="collapse navbar-collapse mean-menu" id="navbarSupportedContent" is-nav>
                        <ul class="navbar-nav nav ml-auto">
                          <li class="nav-item">
                            <router-link :to="{ name: 'Home', params: { location: 'Home' }}" style="display: flex; align-items: flex-end; height: 20px;">Home</router-link>
                          </li>
                          <li class="nav-item">
                            <router-link :to="{ name: 'Home', params: { location: 'StarterKit' }}" style="display: flex; align-items: flex-end; height: 20px;">Starter Kit &#9997;&#127996;</router-link>
                          </li>

                          <li class="nav-item">
                            <router-link :to="{ name: 'Home', params: { location: 'SelfpetitionAI' }}" style="display: flex; align-items: flex-end; height: 20px;">Selfpetition AI &#129302;</router-link>
                          </li>

                          <li class="nav-item">
                            <router-link :to="{ name: 'Home', params: { location: 'RFEs' }}" style="display: flex; align-items: flex-end; height: 20px;">RFEs <span style="font-size: 18px; margin-left: 2px">&#127919;</span></router-link>
                          </li>
                          
                          <li class="nav-item">
                            <router-link :to="{ name: 'Home', params: { location: 'Reviews' }}" style="display: flex; align-items: flex-end; height: 20px;">Reviews</router-link>
                          </li>
                          <li class="nav-item">
                              <a href="/prices" class="nav-link" style="display: flex; align-items: flex-end; height: 20px;">Pricing</a>
                          </li>
                          <li class="nav-item">
                              <a href="/contact" class="nav-link" style="display: flex; align-items: flex-end; height: 20px;">Contact</a>
                          </li>

                          <li class="nav-item">
                              <a href="/faq" class="nav-link" style="display: flex; align-items: flex-end; height: 20px;">FAQ</a>
                          </li>
                          <li class="nav-item">
                            <a href="/blog/" class="nav-link" style="display: flex; align-items: flex-end; height: 20px;">Blog</a>
                          </li>
                        </ul>
                    </b-collapse>

                    <div class="others-option"></div>
                </nav>

            </div>
<!--        </div>-->
    </header>
    <!-- End Navbar Area -->
</template>

<script>

import { mapActions } from 'vuex';

export default {
    name: 'ExternalHeaderGreenCard',
    data(){
        return {
            isSticky: false
        }
    },
    mounted() {
        const that = this;
        window.addEventListener('scroll', () => {
            let scrollPos = window.scrollY;
            // eslint-disable-next-line no-console
            if(scrollPos >= 100){
                that.isSticky = true;
            } else {
                that.isSticky = false;
            }
        })
    },
    methods: {
      ...mapActions('account', ['apply']),
      sendEvent(eventType) {
        if (window.gtag) {
          window.gtag('event', eventType); // google analytics
        }
      }
    }
  }
</script>
